import React, { useEffect } from 'react'
import { Radio, Badge } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import messageActions from '../../../../../redux/message/actions'

export const Toggle = ({
    chatContext,
    setChatContext,
    caseId,
}) => {
    const dispatch = useDispatch()
    const { caseTicketList } = useSelector(state => state.caseTickets)
    const { chatList } = useSelector(state => state.message)
    const adminUnreadCount = caseTicketList.find(ticket => ticket.caseId === caseId)?.unreadCount || 0
    const patientChat = chatList.find(chat => chat?.caseId === caseId)
    const patientUnreadCount = patientChat?.count || 0

    useEffect(() => {
        if (!patientChat && caseId) {
            dispatch(messageActions.getChatWithUnreadPatientMessagesCount(caseId))
        }
    }, [caseId, dispatch, patientChat])


    return (
        <div style={{
            width: '100%',
            height: '100px',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '10px',
            position: 'absolute',
            zIndex: 2
        }}>
            <Radio.Group
                onChange={({ target }) => setChatContext(target.value)}
                value={chatContext}
            >
                <Badge count={patientUnreadCount}>
                    <Radio.Button value={'patient'}>Patient</Radio.Button>
                </Badge>
                <Badge count={adminUnreadCount}>
                    <Radio.Button button value={'admin'}>Admin</Radio.Button>
                </Badge>
            </Radio.Group>
        </div>
    )
}
