import React from 'react'
import { Skeleton } from 'antd'

import { formatTenDigitPhoneNumber } from '../../../../../utils/phoneFormatter'

import { Item } from "../../Item"
import CopyStringComponent from '../../../../../components/CopyStringComponent'

import { useCaseViewContext } from '../../../context/CaseViewContext'

import {
    Container,
    Header,
    SubHeader
} from "../../../Styles"
import { parseDate } from "../../../../../utils/dateFormatter";

function renderGender(gender) {
    switch (gender?.toUpperCase()) {
        case 'M':
            return 'Male'
        case 'F':
            return 'Female'
        case 'O':
            return 'Other'
        default:
            return 'N/A'
    }
}

export const Metadata = () => {
    const { caseDetails } = useCaseViewContext()
    const patient = caseDetails?.patient

    return (
        <Container>
            <Skeleton loading={!patient} paragraph={{ rows: 5 }}>
                <Header>{`${patient?.firstName} ${patient?.lastName}`}</Header>
                <SubHeader>{`${renderGender(patient?.gender)}, ${parseDate(patient?.dob)} (${patient?.age}yo)`}</SubHeader>
                <Item label="Patient ID" value={<CopyStringComponent text={patient?._id} />} />
                <Item label="state" value={<strong>{patient?.state?.name}</strong>} />
                <Item label="email" value={<span style={{ textTransform: 'none' }}>{patient?.email}</span>} />
                <Item label="phone" value={formatTenDigitPhoneNumber(patient?.phone)} />
            </Skeleton>
        </Container>
    )
}