import moment from "moment";

export function parseDate(dob) {
    if (!dob) return '';
    const [ date ] = dob.split("T");
    const [ year, month, day ] = date.split("-");

    return `${ month }/${ day }/${ year }`;
}

export const dateOfBirthFormatter = (dob) => {
    return moment(dob).format("MM/DD/YYYY");
}

export const dateTimeFormatter = (date) => {
    if (!moment(date).isValid()) return "NA"
    return moment(date).format("MMMM Do YYYY hh:mm A");
}

export const timeFormatter = (date) => {
    return moment(date).format("hh:mm A");
}
