import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd'

import socket from "../../../../../axios/socket";
import ChatRoomAPI from '../../../../../axios/ChatRoomAPI'

export const useMessaging = ({
    caseId,
}) => {
    const { physicianDetails } = useSelector(state => state.physicianDetails)
    const [patientMessages, setPatientMessages] = useState([]);

    useEffect(() => {
        socket.on("newPatientChatMessageRecieved", (data) => {
            const isSameCase = data.caseId === caseId
            if (isSameCase && data.physician === physicianDetails?.userId) {
                setPatientMessages(list => list.concat(data.messageData))
            }
        });
    }, [caseId, physicianDetails?.userId])

    const fetchPatientMessageList = useCallback(async () => {
        if (!caseId) return
        try {
            const { data } = await ChatRoomAPI.getChatRoomMessages(caseId)
            if (!data?.success) {
                throw new Error("Error fetching patient messages")
            }
            setPatientMessages(data.payload.messages)
        } catch (e) {
            message.error(e)
        }
    }, [caseId]);

    const sendPatientMessage = async message => {
        const payload = {
            chatRoomId: caseId,
            message,
        }
        try {
            const { data } = await ChatRoomAPI.addMessagesToChatRoom(payload)
            if (!data.success) {
                throw Error()
            }
            setPatientMessages(list => list.concat(data.payload))
            return true
        } catch (e) {
            const errorText = 'Error sending message to patient'
            message.error(errorText)
            console.log(errorText, e)
        }
      };

    const markPatientChatAsRead = useCallback(async () => {
        if (!caseId) return
        try {
            const { data } = await ChatRoomAPI.markChatAsRead(caseId)
            if (!data.success) {
                throw new Error('Error marking patient chat as read')
            }
            setPatientMessages(list => list.map(message => {
                if (message.isPatient && !message.read) {
                    return { ...message, read: true };
                }
                return message;
            }));
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
    }, [caseId]);

    useEffect(() => {
        fetchPatientMessageList()
    }, [caseId, fetchPatientMessageList])

    return {
        patientMessages,
        sendPatientMessage,
        markPatientChatAsRead,
    }
}
