import types from "./types"
import { SORT_ORDER } from "../../types/enums";

const sortCallbackMap = {
    [SORT_ORDER.OLDEST]: (a, b) => new Date(b.date) - new Date(a.date),
    [SORT_ORDER.NEWEST]: (a, b) => new Date(a.date) - new Date(b.date),
};

const SwitchSortOrderMap = {
    [SORT_ORDER.OLDEST]: SORT_ORDER.NEWEST,
    [SORT_ORDER.NEWEST]: SORT_ORDER.OLDEST,
};

const initState = {
    sortOrder: SORT_ORDER.NEWEST,
    MessageCount: 0,
    chatCaseId: "",
    chatList: [],
    chatListLoading: false,
    chatListError: "",
    totalCount: 0,
};

export default function message(state = initState, action) {
    switch (action.type) {
        case types.SET_MESSAGE_COUNT:
            return {
                ...state,
                MessageCount: action.payload,
            };

        case types.SET_CHAT_CASE_ID:
            return {
                ...state,
                chatCaseId: action.id,
            };
        case types.SET_UNREAD_PATIENT_MESSAGES_COUNT:
            const { updatedChat } = action.payload;
            const chat = state.chatList.find((chat) => chat?.caseId === updatedChat.caseId);
            const updatedList = chat
                ? state.chatList.map((chat) => chat.caseId === updatedChat.caseId ? updatedChat : chat)
                : [updatedChat, ...state.chatList];
            const sortedList = updatedList.sort((a, b) => new Date(b.count) - new Date(a.count));
            return {
                ...state,
                chatList: sortedList,
            };
        case types.FETCH_CHAT_LIST:
            const newIds = action.payload.chat.map((chat) => chat._id);
            const filteredChatList = state.chatList.filter((chat) => !newIds.includes(chat._id));
            return {
                ...state,
                chatList: filteredChatList.concat(action.payload.chat),
                totalCount: action.payload.totalCount,
                chatListError: "",
            };
        case types.FETCH_CHAT_LIST_LOADING:
            return {
                ...state,
                chatListLoading: action.payload,
                chatListError: action.payload ? "" : state.chatListError,
            };
        case types.FETCH_CHAT_LIST_ERROR:
            return {
                ...state,
                chatListError: action.payload,
            };
        case types.SORT_CHAT_LIST:
            const newSortOrder = SwitchSortOrderMap[state.sortOrder];
            const sortCallback = sortCallbackMap[newSortOrder];
            const sortedChatList = state.chatList.sort(sortCallback);
            return {
                ...state,
                sortOrder: newSortOrder,
                chatList: [...sortedChatList],
            };
        case types.SET_CHAT_LIST:
            return {
                ...state,
                totalCount: action.payload.totalCount,
                chatList: action.payload.chat,
            };
        default:
            return state;
    }
};
