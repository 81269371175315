import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ImageControls } from "./ImageReviewControls";

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
`;

const ModalContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: width 0.3s ease, height 0.3s ease;
    height: ${ ({ imageHeight }) => imageHeight }px;

    img {
        transform: ${ ({ rotation }) => `rotate(${ rotation }deg)` };
        transition: transform 0.3s ease;
        user-select: none;
        max-width: 80%;
        max-height: 80%;
    }
`;

export const ImageReviewContainer = ({ src, alt, onClose }) => {
    const [ rotation, setRotation ] = useState(0);
    const [ imageHeight, setImageHeight ] = useState(0);
    const imgRef = useRef(null);
    const IMAGE_GAP = 20;

    useEffect(() => {
        const updateImageWidth = () => {
            if (imgRef.current) {setImageHeight(imgRef.current.naturalWidth + IMAGE_GAP);}
        };
        updateImageWidth();

        window.addEventListener("resize", updateImageWidth);
        return () => window.removeEventListener("resize", updateImageWidth);
    }, [ src ]);

    return (
        <ModalOverlay>
            <ModalContent onMouseLeave={ onClose } rotation={ rotation } imageHeight={ imageHeight }>
                <ImageControls setRotation={ setRotation }/>
                <img ref={ imgRef }
                     src={ src }
                     alt={ alt }
                />
            </ModalContent>
        </ModalOverlay>
    );
};
