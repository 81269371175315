import React, { memo } from 'react';
import { htmlToText } from "html-to-text";
import { UserOutlined } from "@ant-design/icons";
import { displayTimestamp } from '../../../../../utils/displayTimestamp';

import {
    Msg,
    StyledAvatar,
    MsgBubble,
    MsgInfo,
    MsgInfoName,
    MsgInfoTime,
    MsgText,
    ImageWrapper,
    Image
} from './ChatMessage.styles';

function renderAvatar(avatar, isOwnMessage) {
    const margin = isOwnMessage ? 'left' : 'right';
    if (avatar) {
        return <StyledAvatar size="large" src={avatar} margin={margin} />
    }
    return <StyledAvatar size="large" icon={<UserOutlined />} margin={margin} />
}

export const ChatMessage = memo(({
    avatar,
    timestamp,
    handleMarkTicketAsRead,
    isOwnMessage,
    isUnread,
    contentSrc,
    message,
    authorName,
}) => {
    return (
        <Msg
            align={isOwnMessage ? 'right' : 'left'}
            key={timestamp}
            onClick={() => isUnread && handleMarkTicketAsRead()}
        >
            {renderAvatar(avatar, isOwnMessage)}
            <MsgBubble isOwnMessage={isOwnMessage} isUnread={isUnread}>
                <MsgInfo>
                    <MsgInfoName>{authorName}</MsgInfoName>
                    <MsgInfoTime>{displayTimestamp(timestamp)}</MsgInfoTime>
                </MsgInfo>
                {
                    contentSrc && (
                        <ImageWrapper>
                            <Image src={contentSrc} alt="uploaded-content-view" />
                        </ImageWrapper>
                    )
                }
                {
                    message && (
                        <MsgText>{htmlToText(message)}</MsgText>
                    )
                }
            </MsgBubble>
        </Msg>
    );
});
