import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd'
import { useDispatch } from 'react-redux'

import socket from "../../../../../axios/socket";
import TicketAPI from '../../../../../axios/TicketApi'
import caseTicketActions from '../../../../../redux/caseTickets/actions'

export const useTickets = ({
    caseId,
}) => {
    const dispatch = useDispatch();
    const { physicianDetails } = useSelector(state => state.physicianDetails)
    const [adminMessages, setAdminMessages] = useState([]);

    useEffect(() => {
        socket.on("newTicketChatMessageRecieved", (data) => {
            console.log("========= useTickets newTicketChatMessageRecieved event data ==========", data);
            const { users, caseId: ticketCaseId, messageData } = data;
            const isSameCase = ticketCaseId === caseId
            if (isSameCase && users?.includes(physicianDetails?.userId)) {
                const newMessage = { ...messageData, created: messageData.createAt }
                setAdminMessages(list => list.concat(newMessage))
            }
          });
    }, [physicianDetails?.userId, caseId])

    const fetchMessages = useCallback(async () => {
        if (!caseId) return
        try {
            const { data } = await TicketAPI.getTicketChat({ caseId })

            if (!data.success || !data.payload) {
                throw new Error('Error fetching admin support chat')
            }

            const { payload } = data;

            const timestampMessageMapping = payload.data.map(message => ({ ...message, created: message.createAt }))
            setAdminMessages(timestampMessageMapping.reverse())
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
    }, [caseId])

    const sendAdminChatMessage = async message => {
        if (!caseId) return
        try {
            await TicketAPI.sendChatMessage({ caseId, message })
            return true
        } catch (e) {
            console.log('Error sending new admin support message', e)
            message.error(e.reponse?.data?.message || e.message)
        }
    }

    const markAdminTicketChatAsRead = useCallback((ticketId) => {
        const markAdminMessagesAsRead = () => {
            setAdminMessages(list => list.map(message => {
              if (message.readBy.includes('physician')) {
                return message;
              }
              return {
                ...message,
                readBy: [...message.readBy, 'physician'],
              };
            }));
        }
      dispatch(caseTicketActions.markTicketAsRead(ticketId, markAdminMessagesAsRead));
    }, [dispatch, setAdminMessages])

    useEffect(() => {
        fetchMessages()
    }, [fetchMessages])

    return {
        adminMessages,
        sendAdminChatMessage,
        markAdminTicketChatAsRead,
    }
}
