/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Layout, notification } from "antd";
import { Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment'

// import CSS
import "./HomePage.css";

// import functions
import { SocketContextProvider } from "../../context/socketContext";
import { TablePreferencesProvider } from '../../context/TablePreferencesProvider'
import { userConstants } from "../../constants";
import waitingRoom from "../../redux/waitingRoom";
import messageStore from "../../redux/message";
import notifications from "../../redux/notifications";
import physicianDetails from "../../redux/physicianDetails";
import entities from "../../redux/entities";
import additionalInfoRequired from "../../redux/additionalInfoRequired";
import rxChange from "../../redux/rxChange";
import rxRenewal from "../../redux/rxRenewals";
import caseTickets from "../../redux/caseTickets";
import referrals from "../../redux/referrals"
import caseQueue from "../../redux/caseQueue"
import rxRequests from "../../redux/rxRequests"

import { useReportsWithSockets } from "../../hooks/useReportsWithSockets";

// import common functions
import decodeToken from "../../utils/decodeToken";
import socket, { socket_init } from "../../axios/socket";

// import components
import HeaderPage from "./components/HeaderPage";
import { Sidebar } from "./components/Sidebar";
import { VerifyIdentity } from "./IdentityVerification";

import socketTwo, { socketTwo_init } from "../../axios/socketTwo";

import { ConsultTypeEnum, PhysicianEmploymentStatusEnum, PhysicianTagEnum } from "../../types/enums"
import { AppRouter } from './components/AppRouter'
import config from '../../config'

import { useToggleMenu } from "../../context/MenuProvider";

const {
  STORE_AND_FORWARD,
  AUDIO
} = ConsultTypeEnum

const { Content } = Layout;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const HomePage = (props) => {
  const { actions, loggedInPhysicianDetails } = props;
  const [userName, setUserName] = useState();
  const [showIdentityVerificationFlow, setShowIdentityVerificationFlow] = useState(false)
  const { isMenuOpen } = useToggleMenu();

  const history = useHistory();
  const isInternalPhysician = loggedInPhysicianDetails?.employmentStatus === 'internal physicians'
  const PROVIDER_HANDLES_TESTO_CASES = loggedInPhysicianDetails?.teleMedicineCategories?.includes('Testosterone')

  const physicianTags = loggedInPhysicianDetails?.employmentStatus.tags || []

  const physicianUsesReferralsFeature =
    physicianTags.includes(PhysicianTagEnum.NEEDS_REVIEW) ||
    physicianTags.includes(PhysicianTagEnum.REVIEWER)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const statusParams = (status, additionalStatus) => {
    const statusParamData = {
      status: status,
    };
    if (additionalStatus && additionalStatus.length) {
      statusParamData["additional-status"] = additionalStatus;
    }
    return statusParamData;
  };

  const handleResize = () => setWindowDimensions(getWindowDimensions());

  const getPhysicianId = () => {
    if (loggedInPhysicianDetails?.userId) {
      return loggedInPhysicianDetails.userId;
    }
    const token = window.localStorage.getItem("token");
    if (!token) {
      return null
    }
    const decodedTokenData = decodeToken(token);
    if (!decodedTokenData || !Object.keys(decodedTokenData).length) {
      return null
    }
    const { user } = decodedTokenData;
    return user?._id || null;
  };

  const initiateCommonAPIs = () => {
    socket.connect();
    socketTwo.connect();
    socketTwo_init();
    socket_init();
    actions.getClientsList();
    actions.getStatesList();
    actions.getTelemedicineCategoriesList();
    actions.getArticleCategoryList();
    actions.getCredentialsList();
  };

  useEffect(() => {
    if (loggedInPhysicianDetails) {
      actions.getRxRequestsAndErrors()
      const isInternalPhysician = loggedInPhysicianDetails.employmentStatus === PhysicianEmploymentStatusEnum.INTERNAL_PHYSICIAN

      isInternalPhysician && socket.on("rxRenewal", (data) => {
          data.physician === getPhysicianId() && actions.getRxRenewalCaseCount();
          if ([STORE_AND_FORWARD, AUDIO].includes(data.consultationType) && !data.physician) {
            actions.addCaseToQueue(loggedInPhysicianDetails, data)
          }
      });

      isInternalPhysician && socket.on("newCase", data => {
        if ([STORE_AND_FORWARD, AUDIO].includes(data.consultationType)) {
          actions.addCaseToQueue(loggedInPhysicianDetails, data)
        }
      })

      loggedInPhysicianDetails.userId && isInternalPhysician && socket.on("rxChange", (data) => {
        const { physician } = data;
        if (physician === getPhysicianId()) {
            actions.getRxChangeCaseCount();
            actions.rxChangeCaseNotification();
        }

        if ([STORE_AND_FORWARD, AUDIO].includes(data.consultationType) && !data.physician) {
          actions.addCaseToQueue(loggedInPhysicianDetails, data)
        }
      });

      socket.on("caseAccepted", ({ caseId }) => actions.removeCaseFromQueue(caseId))
    }
  }, [loggedInPhysicianDetails])

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setUserName(window.localStorage.getItem("useremail"));
    if (window.localStorage.getItem("type") === userConstants.USER_PHYSICIAN) {
      initiateCommonAPIs();
      actions.getCaseTicketNotificationCount();
      actions.getUnreadPatientChatsCount();
      actions.getCaseListInWaitingRoom(statusParams("waiting room"));
      actions.getPhysicianDetails();
      physicianUsesReferralsFeature && actions.getReferralCases()
      actions.getCaseQueueCount(isInternalPhysician ? 'internal' : 'external')

      actions.getAdditionalInfoCaseUpdateCount(
        statusParams(
          "additional info required",
          "invalid face photo,invalid photo id"
        )
      );

      const statusInfoParams = {};
      statusInfoParams["status"] = "additional info required,invalid face photo,invalid photo id";
      actions.getAdditionalInfoCaseCount(statusInfoParams);


        socket.on("refreshCases", (data) => { actions.getCaseListInWaitingRoom(statusParams("waiting room")); });
        socket.on("rxChange", (data) => {
            const { physician } = data;
            if (physician === getPhysicianId()) {
                actions.getRxChangeCaseCount();
                actions.rxChangeCaseNotification();
            }
        });

      socket.on("additionalInfoRequired", (data) => {
        const { physician } = data;
        if (physician === getPhysicianId()) {
          actions.getAdditionalInfoCaseUpdateCount(
            statusParams(
              "additional info required",
              "invalid face photo,invalid photo id"
            )
          );
          actions.additionalInfoCaseUpdateNotification();
        }
      });

      socket.on("additionalInfoMessageReceived", (data) => {
        const { physician } = data;
        if (physician === getPhysicianId()) {
          actions.getAdditionalInfoCaseCount(statusInfoParams);
        }
      });

      socket.on("newPatientChatMessageRecieved", (data) => {
        const { physician, caseId } = data;
        if (physician === getPhysicianId()) {
          actions.getUnreadPatientChatsCount();
          caseId && actions.getChatWithUnreadPatientMessagesCount(caseId);
        }
      });

      socket.on("markedChatAsRead", (data) => {
        const { physicianId, caseId } = data;
        if (physicianId === getPhysicianId()) {
          actions.getUnreadPatientChatsCount();
          caseId && actions.getChatWithUnreadPatientMessagesCount(caseId);
        }
      });

      socket.on("newTicketChatMessageRecieved", (data) => {
        console.log("========= HomePage newTicketChatMessageRecieved event data ==========", data);
        const { users, ticketId } = data;
        if (users?.includes(getPhysicianId())) {
          actions.getCaseTicketNotificationCount();
          actions.getUnreadMessagesCountByTicketIds([ticketId]);
        }
      });

      socket.on("markedTicketAsRead", (data) => {
        if (getPhysicianId() === data.userId) {
          actions.getUnreadMessagesCountByTicketIds([data.ticketId]);
        }
      });

      //TODO: clearTicketNotificationSend is deprecated
      socket.on("clearTicketNotificationSend", (data) => {
        const { userId } = data
        if (userId && getPhysicianId() === userId) {
          actions.getCaseTicketNotificationCount();
        }
      });

      socket.on("syncConsultAssigned", (data) => {
        const { physician } = data;
        if (physician === getPhysicianId()) {
          actions.audioVideoCaseNotification();
        }
      });
      socket.on("asyncConsultAssigned", (data) => {
        const { physician } = data;
        if (physician === getPhysicianId()) {
          actions.pendingPrescriptionCaseNotification();
        }
      });
      socketTwo.on("asyncConsultAssigned", (data) => {
        const { physician } = data;
        if (physician === getPhysicianId()) {
          actions.pendingPrescriptionCaseNotification();
        }
      });

      socket.on("consultUnassigned", (data) => {
        const { physician } = data;
        if (physician === getPhysicianId()) {
          actions.unassignedCaseNotification();
        }
      });

      socket.on("reviewCasesUpdated", () => {
        physicianUsesReferralsFeature && actions.getReferralCases()
      });

      socket.on("consultationReminder", (data) => {
        const { assignedPhysician, consultationType, caseId, startTime } = data

        if (assignedPhysician === getPhysicianId()) {
          const minutesUntilConsult = moment(startTime).diff(new Date(), 'minutes')

          notification.open({
            message: 'Upcoming Consultation Reminder',
            description: `You have ${consultationType === 'Audio' ? 'an' : 'a'} ${consultationType.toLowerCase()} consultation starting in ${minutesUntilConsult} minutes. Click to view case.`,
            duration: 10,
            onClick: () => history.push(`/dashboard/physician/caseview/${caseId}`)
          })
        }
      });

      socket.on('rxRequest', (message) => {
        const { type, providerId } = message
        if (providerId === getPhysicianId()) {
          actions.rxRequestNotification({ type })
        }
      })

      socket.on('rxError', (message) => {
        const { type, providerId } = message
        if (providerId === getPhysicianId()) {
          actions.rxRequestNotification({ type, providerId })
        }
      })

      socketTwo.on("consultUnassigned", (data) => {
        const { physician } = data;
        if (physician === getPhysicianId()) {
          actions.unassignedCaseNotification();
        }
      });

      return () => {
        socket.removeAllListeners([
          "refreshCases",
          "rxChange",
          "additionalInfoRequired",
          "newPatientChatMessageRecieved",
          "newTicketChatMessageRecieved",
          "clearTicketNotificationSend",
          "syncConsultAssigned",
          "asyncConsultAssigned",
          "consultUnassigned",
        ]);
        socket.close();
        window.removeEventListener("resize", handleResize);
      };
    }
    return () => {
      socketTwo.removeAllListeners([
        "asyncConsultAssigned",
        "asyncConsultAssigned",
      ]);
      socketTwo.close();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useReportsWithSockets()

  useEffect(() => {
    const { width } = windowDimensions;
    setScreenWidth(width);
  }, [windowDimensions]);

  let HeaderProps = {
    screenWidth: screenWidth,
    userName: userName,
    idVerified: loggedInPhysicianDetails?.idVerified,
    pinSet: loggedInPhysicianDetails?.pinSet,
    tfaActivated: loggedInPhysicianDetails?.tfaActivated,
    setShowIdentityVerificationFlow
  };

  const handleFinishIPDVerification = () => {
    actions.getPhysicianDetails()
    setShowIdentityVerificationFlow(false)
  }

  return window.localStorage.getItem("token") !== null &&
    window.localStorage.getItem("type") === "physician" ? (
    <SocketContextProvider value={socket}>
      <SocketContextProvider value={socketTwo}>
        <TablePreferencesProvider>
          <Layout className="outer-layout">
            <HeaderPage
              {...HeaderProps}
              provider={loggedInPhysicianDetails}
              beginIdentityVerification={() => setShowIdentityVerificationFlow(true)}
            />
            <Layout style={{ display: 'flex'}}>
              <Sidebar/>
              {
                config.allowIdVerification === 'true' &&
                !!loggedInPhysicianDetails &&
                PROVIDER_HANDLES_TESTO_CASES &&
                (!loggedInPhysicianDetails.idVerified || !loggedInPhysicianDetails.pinSet || !loggedInPhysicianDetails.tfaActivated) && (
                <VerifyIdentity
                  idVerified={loggedInPhysicianDetails?.idVerified}
                  pinSet={loggedInPhysicianDetails?.pinSet}
                  visible={showIdentityVerificationFlow}
                  setShowIdentityVerificationFlow={setShowIdentityVerificationFlow}
                  onFinish={handleFinishIPDVerification}
                />
              )}
              <Layout>
              {
                !!loggedInPhysicianDetails && (
                  <Content className="inner-content" style={{
                    width: isMenuOpen ? 'calc(100% - 280px)' : 'calc(100% - 70px)',
                    marginLeft: isMenuOpen ? '280px' : '70px'
                  }}>
                    <AppRouter
                      loggedInPhysicianDetails={loggedInPhysicianDetails}
                      isSidebarCollapsed={!isMenuOpen}
                    />
                  </Content>
                )
              }
              </Layout>
            </Layout>
          </Layout>
        </TablePreferencesProvider>
      </SocketContextProvider>
    </SocketContextProvider>
  ) : (
    <Redirect to="/login-physician" />
  );
};

const mapStateToProps = (state) => {
  const { physicianDetails } = state.physicianDetails;

  return {
    loggedInPhysicianDetails: physicianDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getWaitingRoomCaseList } = waitingRoom.actions;
  const { getUnreadPatientChatsCount, getChatWithUnreadPatientMessagesCount } = messageStore.actions;
  const { getRxRequestsAndErrors } = rxRequests.actions
  const {
    pendingPrescriptionCaseNotification,
    audioVideoCaseNotification,
    additionalInfoCaseUpdateNotification,
    rxChangeCaseNotification,
    unassignedCaseNotification,
  } = notifications.actions;
  const { getAdditionalInfoCaseUpdateCount, getAdditionalInfoCaseCount } =
    additionalInfoRequired.actions;
  const { getPhysicianDetails } = physicianDetails.actions;
  const {
    getClientsList,
    getStatesList,
    getTelemedicineCategoriesList,
    getCredentialsList,
    getArticleCategoryList,
  } = entities.actions;
  const { getRxChangeCaseCount } = rxChange.actions;
  const { getRxRenewalCaseCount } = rxRenewal.actions;
  const { getCaseTicketNotificationCount, getUnreadMessagesCountByTicketIds } = caseTickets.actions;
  const { getReferralCases } = referrals.actions
  const { getCaseQueueCount, addCaseToQueue, removeCaseFromQueue } = caseQueue.actions;
  const { rxRequestNotification } = notifications.actions

  return {
    actions: bindActionCreators(
      {
        getCaseListInWaitingRoom: getWaitingRoomCaseList,
        getUnreadPatientChatsCount,
        getChatWithUnreadPatientMessagesCount,
        pendingPrescriptionCaseNotification,
        audioVideoCaseNotification,
        additionalInfoCaseUpdateNotification,
        rxChangeCaseNotification,
        getPhysicianDetails,
        unassignedCaseNotification,
        getClientsList,
        getStatesList,
        getTelemedicineCategoriesList,
        getCredentialsList,
        getAdditionalInfoCaseUpdateCount,
        getRxChangeCaseCount,
        getRxRenewalCaseCount,
        getArticleCategoryList,
        getCaseTicketNotificationCount,
        getUnreadMessagesCountByTicketIds,
        getAdditionalInfoCaseCount,
        getReferralCases,
        getCaseQueueCount,
        addCaseToQueue,
        removeCaseFromQueue,
        rxRequestNotification,
        getRxRequestsAndErrors
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
