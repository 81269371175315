
const  types =  {
    SET_MESSAGE_COUNT: 'SET_MESSAGE_COUNT',
    SET_CHAT_CASE_ID: 'SET_CHAT_CASE_ID',

    SET_UNREAD_PATIENT_MESSAGES_COUNT: 'SET_UNREAD_PATIENT_MESSAGES_COUNT',

    FETCH_CHAT_LIST: 'FETCH_CHAT_LIST',
    FETCH_CHAT_LIST_LOADING: 'FETCH_CHAT_LIST_LOADING',
    FETCH_CHAT_LIST_ERROR: 'FETCH_CHAT_LIST_ERROR',

    SORT_CHAT_LIST: 'SORT_CHAT_LIST',
    SET_CHAT_LIST: 'SET_CHAT_LIST',
};

export default types;
