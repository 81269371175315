/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Row, Col, Typography } from "antd";
import { parseDate } from "../../utils/dateFormatter";
import { useAudioCallContext } from "../../context/AudioCallProvider";
import { ConsultTypeEnum } from "../../types/enums";

const { Text } = Typography;
const { VIDEO, AUDIO } = ConsultTypeEnum;

const CasePatientDetails = ({ caseDetails }) => {
  const { patient, consultationType } = caseDetails;

  const audioCall = useAudioCallContext()

  const heightInfo = Number(caseDetails?.heightFt)
    ? Number(caseDetails?.heightIn)
      ? `${caseDetails.heightFt} ft ${caseDetails.heightIn} in`
      : `${caseDetails.heightFt} ft`
    : null;

  const weightInfo = Number(caseDetails?.weight)
    ? `${caseDetails.weight} lbs`
    : null

  const mapGenderKeyToGender = (genderKey) => {
    switch (genderKey) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "T":
        return "Trans";
      case "O":
        return "Other";
      default:
        return "";
    }
  };

  const isSyncConsultType = [VIDEO, AUDIO].includes(consultationType);

  const onClickPhone = () => {
    if (!isSyncConsultType) {
      return;
    }
    audioCall.initiate(caseDetails?._id)
  }

  return (
    <Form name="case-details-patient">
      <Row className="case-patient-details">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Patient Id">
            <Text className="sub-content-color" strong>
              {patient?._id || ""}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label="First Name">
            <Text className="sub-content-color" strong>
              {patient?.firstName?.toUpperCase() || ""}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Last Name">
            <Text className="sub-content-color" strong>
              {patient?.lastName?.toUpperCase() || ""}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Age">
            <Text className="sub-content-color" strong>
              {patient?.age || ""}
            </Text>
          </Form.Item>
        </Col>

        {!!heightInfo && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Height">
              <Text className="sub-content-color" strong>{heightInfo}</Text>
            </Form.Item>
          </Col>
        )}
        {!!weightInfo && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Weight">
              <Text className="sub-content-color" strong>{weightInfo}</Text>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Gender">
            <Text className="sub-content-color" strong>
              {mapGenderKeyToGender(patient?.gender?.toUpperCase())}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="DOB">
            <Text className="sub-content-color" strong>
              { parseDate(patient?.dob) }
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Email">
            <Text className="sub-content-color" strong>
              {patient?.email || ""}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="First Address">
            <Text className="sub-content-color" strong>
              {patient?.address1 || ""}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Second Address">
            <Text className="sub-content-color" strong>
              {patient?.address2 || ""}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="City">
            <Text className="sub-content-color" strong>
              {patient?.city || ""}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="State">
            <Text className="sub-content-color" strong>
              {patient?.state?.name || ""}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Zip Code">
            <Text className="sub-content-color" strong>
              {patient?.zipCode || ""}
            </Text>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Phone"
            onClick={onClickPhone}
            style={{ cursor: isSyncConsultType ? "pointer" : "default" }}
          >
            <Text className="sub-content-color" strong>
              {patient?.phone || ""}
            </Text>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CasePatientDetails;
