import Axios from "./Axios";

export default class ChatRoomApi {
  static getToken() {
    const token = window.localStorage.getItem("token");
    return token;
  }

  static getChatRoomMessages(caseId, params ) {
    const token = this.getToken();
    return Axios({
      url: `/chatroom/messages/${caseId}`,
      method: "GET",
      params:params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getChatList(params) {
    const token = this.getToken();
    return Axios({
      url: `/chatroom/messages`,
      method: "GET",
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static uploadChatImage = (data) => {
    const token = this.getToken();
    return Axios({
      url: `/chatroom/upload`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  };

  static markChatAsRead(caseId) {
    const token = this.getToken();
    return Axios({
      url: `/chatroom/mark-chat-as-read/${caseId}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static addMessagesToChatRoom(data) {
    const token = this.getToken();
    return Axios({
      url: `/chatroom/messages`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  }
}
