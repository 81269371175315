import React, { useState, useEffect, useCallback } from "react"

import {
  Module
} from "../../Styles"

import { Toggle } from "./components/Toggle"
import { MessageList } from "./components/MessageList"
import { Controls } from "./components/Controls"
import { ImageUploadModal } from "./components/ImageUploadModal"

import { useTickets } from './hooks/useTickets'
import { useMessaging } from "./hooks/useMessaging";
import { useCaseViewContext } from "../../context/CaseViewContext"
import { ChatType } from "./types"

export const Messaging = () => {
  const [chatContext, setChatContext] = useState(ChatType.PATIENT)
  const [imageUploadVisibility, setImageUploadVisibility] = useState(false);
  const { caseDetails, ticket } = useCaseViewContext()

  const { caseId } = caseDetails || {}

  const [messages, setMessages] = useState({
    loading: false,
    list: [],
  });

  const {
    adminMessages,
    sendAdminChatMessage,
    markAdminTicketChatAsRead,
  } = useTickets({
    caseId,
  })

  const {
    patientMessages,
    sendPatientMessage,
    markPatientChatAsRead,
  } = useMessaging({
    caseId,
  })

  const handleSendMessage = message => {
    const sendFn = chatContext === ChatType.PATIENT
      ? sendPatientMessage
      : sendAdminChatMessage

    return sendFn(message)
  };

  const onImageUploadModalVisibilityChange = () => {
    setImageUploadVisibility(prevStatus => !prevStatus);
  }

  useEffect(() => {
    const MessagesByChatType = {
      [ChatType.PATIENT]: patientMessages,
      [ChatType.ADMIN]: adminMessages,
    }
    const messages = MessagesByChatType[chatContext] || patientMessages
    setMessages(state => ({ ...state, list: messages }))
  }, [chatContext, patientMessages, adminMessages])

  const handleMarkChatAsRead = useCallback(() => {
    if (chatContext === ChatType.ADMIN && ticket) {
      markAdminTicketChatAsRead(ticket._id)
    }
    else if (chatContext === ChatType.PATIENT) {
      markPatientChatAsRead()
    }
  }, [chatContext, ticket, markAdminTicketChatAsRead, markPatientChatAsRead])

  return (
    <Module
      width="30%"
      border="none"
      padding="0"
      gap="0"
      style={{
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Toggle
        chatContext={chatContext}
        setChatContext={setChatContext}
        caseId={caseId}
      />
      <MessageList
        chatContext={chatContext}
        messages={messages}
        handleMarkTicketAsRead={handleMarkChatAsRead}
      />
      <Controls
        chatContext={chatContext}
        handleSendMessage={handleSendMessage}
        handleMarkTicketAsRead={handleMarkChatAsRead}
        showModal={onImageUploadModalVisibilityChange}
      />
      { imageUploadVisibility && (
        <ImageUploadModal
          closeModal={onImageUploadModalVisibilityChange}
        /> )
      }
    </Module>
  )
}
