import types from "./types";
import handleAPIErrorResponse from "../../utils/handleAPIResponseError";
import API from "../../axios/physician/PhysicianApi";
import ChatRoomAPI from "../../axios/ChatRoomAPI";

const setMessageCount = (payload) => ({
    type: types.SET_MESSAGE_COUNT,
    payload,
});

const setChatCaseId = (id) => ({
    type: types.SET_CHAT_CASE_ID,
    id,
});

const setUnreadPatientMessagesCount = (payload) => ({
    type: types.SET_UNREAD_PATIENT_MESSAGES_COUNT,
    payload,
});

const setFetchChatList = (payload) => ({
    type: types.FETCH_CHAT_LIST,
    payload,
});

const setFetchChatListLoading = (payload) => ({
    type: types.FETCH_CHAT_LIST_LOADING,
    payload,
});

const setFetchChatListError = (payload) => ({
    type: types.FETCH_CHAT_LIST_ERROR,
    payload,
});

const sortChatList = () => ({
    type: types.SORT_CHAT_LIST,
});

const setChatList = (payload) => ({
    type: types.SET_CHAT_LIST,
    payload,
});

const getUnreadPatientChatsCount = () => {
    return (dispatch) => {
        API.getUnreadPatientChatsCount().then((res) => {
            if (res.data.success) {
                dispatch(setMessageCount(res.data.payload));
            }
        }).catch(
            (error) => {
                handleAPIErrorResponse(error, "Error occured while fetching latest Message Count");
            });;
    };
}

const getChatWithUnreadPatientMessagesCount = (caseId) => {
    return (dispatch) => {
        API.getChatWithUnreadPatientMessagesCount(caseId)
            .then(res => {
                if (res.data.success && !res.data.payload.updatedChat) {
                    return; //there is no chat with patient
                }
                dispatch(setUnreadPatientMessagesCount(res.data.payload));
            })
            .catch(err => handleAPIErrorResponse(err, "Error getChatWithUnreadPatientMessagesCount"));
    };
}

const fetchChatList = (params) => {
    return async (dispatch) => {
        dispatch(setFetchChatListLoading(true));
        try {
            const res = await ChatRoomAPI.getChatList(params);
            if (!res.data.success) {
                throw new Error();
            }
            dispatch(setFetchChatList(res.data.payload));
        } catch (error) {
            const errorMessage = "Error occured while fetching chat list";
            dispatch(setFetchChatListError(errorMessage));
            handleAPIErrorResponse(error, errorMessage);
        } finally {
            dispatch(setFetchChatListLoading(false));
        }
    }
}

const actions = {
    setMessageCount,
    setChatCaseId,
    setChatList,
    getUnreadPatientChatsCount,
    getChatWithUnreadPatientMessagesCount,
    fetchChatList,
    sortChatList,
};

export default actions;
