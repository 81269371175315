import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Select,
  Typography,
  Spin,
  Button,
  Drawer,
  Divider,
  Tooltip,
  message,
} from "antd";
import { LoadingOutlined, MenuOutlined, PlusOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import messageStore from "../../redux/message";
import PhysicianApi from "../../axios/physician/PhysicianApi";
import { SORT_ORDER } from "../../types/enums";

import ChatList from "./ChatList";
import MessageList from "./MessageList";
import CaseDetailsDraw from "./CaseDetailsDraw";
import "./Messages.css";

const { Text } = Typography;

const caseListParams = {
  index: 1,
  "case-count": 25,
};

const chatListParams = {
  page: 1,
  limit: 25,
};

const Messages = () => {
  const dispatch = useDispatch();
  const { chatCaseId, chatListLoading, chatList, totalCount, sortOrder } = useSelector((state) => state.message);
  const [chatListDrawCollapse, setChatListDrawCollapse] = useState(false);
  const [caseDetailsDrawCollapse, setCaseDetailsDrawCollapse] = useState(false);
  const [caseIdForCaseDetails, setCaseIdForCaseDetails] = useState(null);
  const [caseDetails, setCaseDetails] = useState({});
  const [caseList, setCaseList] = useState([]);
  const [totalCountCase, setTotalCountCase] = useState();
  const [indexCaseList, setIndexCaseList] = useState(1);
  const [caseListLoading, setCaseListLoading] = useState(false);
  const [indexChatList, setIndexChatList] = useState(1);
  const [caseInChatSelected, setCaseInChatSelected] = useState(false);
  const [selectedCase, setSelectedCase] = useState();
  const [caseSearchTerm, setCaseSearchTerm] = useState("");

  const isWheel = () => {
    return window.localStorage.getItem("role") === "Wheel";
  };

  const fetchCaseList = useCallback((params) => {
    setCaseListLoading(true);
    PhysicianApi.getCaseList(params)
      .then((res) => {
        if (!res.data.success) {
          throw Error();
        }
        setCaseList(list => list.concat(res.data.payload.data));
        setTotalCountCase(res.data.payload.count);
      })
      .catch((err) => {
        message.error("unable to fetch case , please try again");
        console.log("Fetch case error: ", err);
      })
      .finally(() => {
        setCaseListLoading(false);
      });
  }, []);

  const handleSelectClear = useCallback(() => {
    fetchCaseList(caseListParams);
    setIndexCaseList(1);
    setIndexChatList(1);
    setCaseSearchTerm("");
    dispatch(messageStore.actions.fetchChatList(chatListParams));
    dispatch(messageStore.actions.setChatCaseId());
  }, [dispatch, fetchCaseList]);

  const isFetched = useRef(false);

  useEffect(() => {
    if (!chatCaseId) {
      if (!caseList.length && indexCaseList === 1){
        fetchCaseList(caseListParams);
      }
      if (indexChatList === 1 && !caseInChatSelected && !isFetched.current) {
          dispatch(messageStore.actions.fetchChatList(chatListParams));
          isFetched.current = true;
      }
    } else if (!caseInChatSelected) {
      handleNavigatedChatCase();
      if (indexCaseList === 1) {
        fetchCaseList(caseListParams);
      }
    }
  }, [caseInChatSelected, dispatch, fetchCaseList]);

  useEffect(() => {
    if (caseInChatSelected) {
      if (!chatList.length && !caseList.length) {
        handleSelectClear();
      }
    }
  }, [chatList, caseList, handleSelectClear, caseInChatSelected]);

  const handleChatSidebarCollapseChange = () => {
    setChatListDrawCollapse((previousState) => !previousState);
  };

  const handleCaseDetailsSidebarCollapseChange = () => {
    setCaseDetailsDrawCollapse((previousState) => !previousState);
  };

  const handleCaseDetailsView = (caseId) => {
    setCaseIdForCaseDetails(caseId);
    setCaseDetailsDrawCollapse((previousState) => !previousState);
  };

  const handleCaseChangeAndAdd = (value) => {
    if (value) {
      const selectedCaseFromDropdown = JSON.parse(value);
      const chatNeedsToBeSelected = {
        caseId: selectedCaseFromDropdown.caseId,
        count: selectedCaseFromDropdown.unReadChatCount,
        date: "",
        name: selectedCaseFromDropdown.patientName,
        patientId: selectedCaseFromDropdown.patientId,
        physicianId: selectedCaseFromDropdown.physicianId,
        photo: selectedCaseFromDropdown.patientPhoto,
      };
      dispatch(messageStore.actions.setChatList({ chat: [chatNeedsToBeSelected], totalCount: 1 }));
      setCaseDetails(chatNeedsToBeSelected);
      const { caseId } = chatNeedsToBeSelected;
      setSelectedCase(value);
      dispatch(messageStore.actions.setChatCaseId(caseId));
    } else {
      dispatch(messageStore.actions.setChatList({ chat: [], totalCount: 0 }));
      setCaseList([]);
      setCaseInChatSelected(true);
      setSelectedCase();
    }
  };

  const handleChatSelection = (data) => {
    setCaseDetails(data);
  };

  function handleNavigatedChatCase() {
    if (chatCaseId) {
      const params = {
        "case-id": chatCaseId,
      };
      setCaseListLoading(true);
      PhysicianApi.getCaseList(params)
        .then((res) => {
          const { data } = res;
          if (!data.success) return message.error("Unable to fetch case, please try again!");
          if (data.payload.data[0]) {
            const checkChatNavigatedCaseList = data.payload.data[0];
            const selectNavigatedChatCase = {
              caseId: checkChatNavigatedCaseList.caseId,
              count: checkChatNavigatedCaseList.unReadChatCount,
              date: "",
              name: checkChatNavigatedCaseList.patientName,
              patientId: checkChatNavigatedCaseList.patientId,
              physicianId: checkChatNavigatedCaseList.physicianId,
              photo: checkChatNavigatedCaseList.patientPhoto,
            };
            dispatch(messageStore.actions.setChatList({ chat: [selectNavigatedChatCase], totalCount: 1 }));
            setCaseDetails(selectNavigatedChatCase);
          }
        })
        .catch(() => {
          message.error("unable to fetch case , please try again!");
        })
        .finally(() => {
          setCaseListLoading(false);
        });
    }
  }
  const handleLoadCaseList = () => {
    const caseListPaginateParams = {
      ...caseListParams,
      index: indexCaseList + 1,
      "search-term": caseSearchTerm,
    };
    setIndexCaseList(indexCaseList + 1);
    fetchCaseList(caseListPaginateParams);
  };

  const handleLoadChatList = () => {
    const chatListPaginateParams = {
      page: indexChatList + 1,
      limit: 10,
    };
    setIndexChatList(indexChatList + 1);
    dispatch(messageStore.actions.fetchChatList(chatListPaginateParams));
  };

  const toggleSortChatList = () => {
    dispatch(messageStore.actions.sortChatList());
  };

  return (
    <div className="message-wrapper">
      <Drawer
        visible={chatListDrawCollapse}
        className="message-chat-list-draw"
        closable
        maskClosable
        placement="left"
        onClose={handleChatSidebarCollapseChange}
        getContainer={false}
      >
        <Spin
          size="large"
          spinning={chatListLoading}
          indicator={<LoadingOutlined spin />}
        >
          {chatList.length ? (
            <>
              <ChatList
                caseDetails={caseDetails}
                chatList={chatList}
                chatSelection={handleChatSelection}
              />
              {totalCount > chatList.length && (
                <div>
                  <Divider style={{ margin: "4px 0" }} />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        padding: "8px",
                        display: "block",
                        cursor: "pointer",
                      }}
                      loading={chatListLoading}
                      onClick={handleLoadChatList}
                    >
                      <PlusOutlined /> Load More
                    </Button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="message-no-chat-selection bg-white-color border-color">
              <Text strong>No Chats yet</Text>
            </div>
          )}
        </Spin>
      </Drawer>

      <Row gutter={[8, 8]}>
        <Col xs={3} sm={3} md={2} lg={0} xl={0}>
          <Button
            icon={<MenuOutlined />}
            className="message-chat-list-box"
            size="large"
            onClick={handleChatSidebarCollapseChange}
          />
        </Col>
        <Col xs={21} sm={21} md={22} lg={24} xl={24}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Tooltip  title={`${sortOrder} first`}>
              <Button
                onClick={toggleSortChatList}
                icon={sortOrder === SORT_ORDER.OLDEST ? <CaretDownOutlined /> : <CaretUpOutlined />}
                style={{ height: 40, width: 40 }}
              />
            </Tooltip>
            <Select
              placeholder="Find case in loaded list"
              showSearch
              allowClear={true}
              size="large"
              value={selectedCase}
              onSearch={setCaseSearchTerm}
              className="message-search-case-dropdown"
              onChange={handleCaseChangeAndAdd}
              getPopupContainer={(trigger) => trigger.parentElement}
              dropdownRender={(menu) => (
                <div>
                  <Spin
                    spinning={caseListLoading}
                    indicator={<LoadingOutlined spin />}
                  >
                    {menu}

                    {totalCountCase > caseList.length && caseSearchTerm === "" && (
                      <>
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            type="primary"
                            size="large"
                            style={{
                              padding: "8px",
                              display: "block",
                              cursor: "pointer",
                            }}
                            onClick={handleLoadCaseList}
                          >
                            <PlusOutlined /> Load More Case
                          </Button>
                        </div>
                      </>
                    )}
                  </Spin>
                </div>
              )}
            >
              {caseList.map((eachCase) => (
                <Select.Option
                  key={eachCase.caseId}
                  value={JSON.stringify(eachCase)}
                >
                  {`${eachCase.category} : ${eachCase.patientName}`}
                </Select.Option>
              ))}
            </Select>
            <Button onClick={() => handleCaseChangeAndAdd()} style={{ height: 40 }}>
              Clear Selections
            </Button>
          </div>
        </Col>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={8}
          xl={8}
          className="message-chat-list-wrapper"
          style={{ backgroundColor: 'none'}}
        >
          <Spin
            size="large"
            spinning={chatListLoading}
            indicator={<LoadingOutlined spin />}
          >
            {chatList.length ? (
              <div>
                <ChatList
                  caseDetails={caseDetails}
                  chatList={chatList}
                  chatSelection={handleChatSelection}
                />

                {totalCount > chatList.length && (
                  <div>
                    <Divider style={{ margin: "4px 0" }} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="primary"
                        size="large"
                        style={{
                          padding: "8px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        loading={chatListLoading}
                        onClick={handleLoadChatList}
                      >
                        <PlusOutlined /> Load More Chats
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="message-no-chat-selection bg-white-color border-color">
                <Text strong>No Chats yet</Text>
              </div>
            )}
          </Spin>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={16}
          className="message-chat-list-wrapper"
        >
          <MessageList
            caseDetails={caseDetails}
            isDifferentPlatform={isWheel()}
            enableCaseDetailsView={handleCaseDetailsView}
          />
        </Col>
      </Row>
      <CaseDetailsDraw
        caseId={caseIdForCaseDetails}
        caseDetailsDrawCollapse={caseDetailsDrawCollapse}
        handleCaseDetailsDrawClose={handleCaseDetailsSidebarCollapseChange}
      />
    </div>
  );
};

export default Messages;
