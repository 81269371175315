import React, { useState } from 'react'
import { FilePdfOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd'
import axios from "axios";

import { SectionHeader } from '../../../SectionHeader'
import { StyledButton } from '../../../Button'

import PhysicianApi from '../../../../../../axios/physician/PhysicianApi'
import { useCaseViewContext } from '../../../../context/CaseViewContext'
import isValidUrl from '../../../../../../utils/isValidURL'

import {
  Container,
  AttachmentsContainer
} from "../../../../Styles"
import { VTooltip } from "../../../../../../shared/ui/Tooltip/Tooltip";
import { ImageReview } from "../../../../../../shared/ui/ImageReview";

export const Attachments = () => {
  const [uploadUrl, setUploadUrl] = useState(null);
  const { caseDetails, fetchCaseDetails } = useCaseViewContext()

  const patient = caseDetails?.patient

  const listAdditionalDocuments = (documents = {}) => {
    let results = [];
    for (const [key, value] of Object.entries(documents)) {
      if (isValidUrl(value)) {
        const fileName = new URL(value).pathname.split('/').pop();

        if (!fileName.toLowerCase().endsWith('.pdf')) {
          results.push(
            <ImageReview
              src={value}
              alt={fileName}
              onClick={() => window.open(value, "_blank")}
            />
          );
        } else {
          results.push(
            <StyledButton
              onClick={() => window.open(value, "_blank")}
              key={key}
            >
              <FilePdfOutlined style={{ fontSize: "500%", color: "#7BAD7E" }} />
            </StyledButton>
          )
        }
      }
    }

    results.push(
      <Upload
        listType="picture"
        key="picture"
        customRequest={async ({ file, onSuccess, onError, onProgress }) => {
          const getArrayBufferFromFile = async () => new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsArrayBuffer(file)
          })

          const data = await getArrayBufferFromFile();

          return axios.put(uploadUrl, data, {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              onProgress({ percent: Math.floor((loaded * 100) / total) })
            }
          }).then((res) => onSuccess(res.data))
            .catch((err) => onError(err, err.response?.body))
        }}
        action={uploadUrl}
        onChange={(info) => {
          if (info.file.status === 'done') {
            const url = new URL(uploadUrl);
            PhysicianApi
              .addAdditionalDocumentUrlToCase(caseDetails.caseId, `${url.origin}${url.pathname}`)
              .then(() => fetchCaseDetails(caseDetails.caseId))
          }
        }}
        beforeUpload={(file) => PhysicianApi.getSignedUploadUrl(caseDetails.caseId, file.name)
          .then(res => setUploadUrl(res.data.signedUploadUrl))
        }
      >
        <VTooltip title='Add Attachment' placement='right'>
          <Button
            type="default"
            className="additional-documents-item"
            title="Upload additional document"
            style={{ width: '150px', height: '150px' }}
            icon={<PlusCircleOutlined style={{ fontSize: "500%", color: "#7BAD7E" }} />}
          />
        </VTooltip>
      </Upload>
    );

    return results
  }


  return (
    <Container>
      <SectionHeader title="Attachments" />
      <AttachmentsContainer>
        {patient?.idProof && <ImageReview src={patient.idProof} alt="patient id" />}
        {patient?.photo && <ImageReview src={patient.photo} alt="patient photo" />}
        {listAdditionalDocuments(caseDetails?.documents)}
      </AttachmentsContainer>
    </Container>
  )
}