import React from "react";
import styled from "styled-components";
import { RotateRightOutlined, RotateLeftOutlined } from "@ant-design/icons";

const rotationProperties = {
    cursor: 'pointer',
    fontSize: '2rem',
    color: 'black'
};

const RotateLeft = styled(RotateLeftOutlined)(rotationProperties);
const RotateRight = styled(RotateRightOutlined)(rotationProperties);

const ControllerContainer = styled.div`
    display: flex;
    gap: 10px;
    z-index: 21;
    position: absolute;
    top: 10px;  
    left: 50%;
    transform: translateX(-50%);
`;


export const ImageControls = ({ setRotation }) => {
    const onRotateLeft = () => setRotation((prev) => prev - 90);
    const onRotateRight = () => setRotation((prev) => prev + 90);

    return (
        <ControllerContainer>
            <RotateLeft onClick={ onRotateLeft }/>
            <RotateRight onClick={ onRotateRight }/>
        </ControllerContainer>
    );
};
