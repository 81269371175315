import React, { useEffect, useRef } from "react";
import { Empty, Skeleton } from "antd";
import moment from "moment-timezone";

import { ChatType } from "../types";
import { ChatMessage } from "./ChatMessage"

import {
  MessageListContainer,
  Messages
} from './MessageList.styles'

import { useCaseViewContext } from "../../../context/CaseViewContext";

export const MessageList = ({
  chatContext,
  messages,
  handleMarkTicketAsRead,
}) => {

  const {
    caseDetails,
    provider,
  } = useCaseViewContext()

  const messageContainerRef = useRef(null);

  useEffect(() => {
    const { current } = messageContainerRef;
    if (current) {
      current.scrollTop = current.scrollHeight;
    }
  }, [messages.list]);

  const createChatMessageProps = (messageData) => {
    const messageAuthor = messageData.author || messageData.chatOwnerDetails?._id
    const isOwnMessage = messageAuthor === provider.userId

    const isUnreadByChatType = {
      [ChatType.PATIENT]: () => !isOwnMessage && !messageData.read,
      [ChatType.ADMIN]: () => !isOwnMessage && !messageData.readBy?.includes("physician"),
    }

    const isUnread = isUnreadByChatType[chatContext]()

    const authorNameByChatType = {
      [ChatType.PATIENT]: () => messageData.authorName || 'Patient',
      [ChatType.ADMIN]: () => `${messageData.chatOwnerDetails?.firstName} ${messageData.chatOwnerDetails?.lastName}`,
    }

    const authorName = isOwnMessage ? 'You' : authorNameByChatType[chatContext]()

    return {
      key: messageData._id,
      timestamp: moment(messageData.created).format("LLL"),
      handleMarkTicketAsRead,
      isOwnMessage,
      isUnread,
      contentSrc: messageData.key,
      message: messageData.message,
      authorName,
    }
  }

  return (
    <Skeleton
      loading={!caseDetails}
      paragraph={{ rows: 50 }}
    >
      <MessageListContainer>
        {
          messages.list?.length === 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No messages yet"
              style={{ alignSelf: 'center', color: '#999999' }}
            />
          )
        }
        <Messages ref={messageContainerRef}>
          {
            messages.list?.map(messageObj => {
              const chatMessageProps = createChatMessageProps(messageObj)
              return <ChatMessage {...chatMessageProps} />
            })
          }
        </Messages>
      </MessageListContainer>
    </Skeleton>
  );
};
