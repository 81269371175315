//import packages
import React, { useState, useEffect, useCallback } from "react";
import "./CaseTicket.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { Spin, Form, Table, Select, Button, Badge } from "antd";
import { LoadingOutlined, MessageOutlined } from "@ant-design/icons";

import { dateTimeFormatter } from "../../utils/dateFormatter";
import caseTickets from "../../redux/caseTickets";
import { userConstants } from "../../constants";

const initialParams = {
  page: 1,
  limit: 10,
  status: "Open",
};

const statusOptions = ["Open", "Closed", "Solved"];

const CaseTicket = (props) => {
  const [form] = Form.useForm();
  const [filterParams, setFilterParams] = useState();
  const [status, setStatus] = useState("Open");
  const [tablePageSize, setTablePageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const {
    actions,
    caseTicketList,
    caseTicketListLoading,
    caseTicketCount,
  } = props;

  const columns = [
    {
      title: "Ticket Id",
      dataIndex: "ticketno",
      key: "ticket-id",
      width: 250,
    },
    {
      title: "Case Id",
      dataIndex: "caseno",
      key: "case-id",
      width: 250,
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patient-name",
      width: 250,
    },
    {
      title: "Last Response Time",
      dataIndex: "",
      key: "last-response-time",
      width: 220,
      render: (eachCase) => (
        <>
          {eachCase.lastResponseTime
            ? dateTimeFormatter(eachCase.lastResponseTime)
            : "NA"}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 80,
    },
    {
      title: "Message",
      dataIndex: "",
      key: "message",
      align: "center",
      width: 180,
      render: (eachCase) => (
        <Badge count={eachCase.unreadCount}>
          <Button
            shape="circle"
            icon={<MessageOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              window.localStorage.setItem("adminChatKey", true);
              history.push(`/dashboard/physician/caseview/${eachCase.caseno}`);
            }}
          />
        </Badge>
      ),
    },
  ];

  const fetchCaseTickets = useCallback((filterValue) => {
    actions.getCaseTicketList(filterValue);
  }, [actions]);

  useEffect(() => {
    fetchCaseTickets(initialParams);
  }, [fetchCaseTickets]);

  const filterValue = () => {
    const data = form.getFieldsValue();
    !data.status ? setStatus("Open") : setStatus(data.status);
    const filter = {};
    filter["page"] = 1;
    filter["limit"] = 10;
    setCurrentPage(1);
    setTablePageSize(10);
    !data.status
      ? (filter["status"] = "Open")
      : (filter["status"] = data.status);
    setFilterParams(filter);
    fetchCaseTickets(filter);
  };

  const clearFilterChange = () => {
    form.resetFields();
    setCurrentPage(1);
    setTablePageSize(10);
    setFilterParams();
    setStatus("Open");
    fetchCaseTickets(initialParams);
  };

  const onChangePage = (value, pageSize) => {
    setCurrentPage(value);
    const paramsData = filterParams ? filterParams : {};
    paramsData["page"] = value;
    paramsData["limit"] = pageSize;
    paramsData["status"] = status;
    fetchCaseTickets(paramsData);
  };

  const onPageSizeChange = (current, size) => {
    setTablePageSize(size);
    setCurrentPage(1);
    const paramsData = filterParams ? filterParams : {};
    paramsData["page"] = current;
    paramsData["limit"] = size;
    paramsData["status"] = status;
    fetchCaseTickets(paramsData);
  };

  const pushRouteCaseDetails = (caseId) => {
    history.push(
      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${caseId}`,
      { from: 'additional-info-required'}
    );
  };

  const handleCaseListClick = (event, record) => {
    event.stopPropagation();
    pushRouteCaseDetails(record.caseno);
  };

  return (
    <div className="case-ticket">
      <h1>Case Tickets</h1>
      <Form layout="vertical" form={form}>
        <div className="case-ticket-filter-wrapper">
          <div className="case-ticket-filter">
            <Form.Item name="status" label="Status">
              <Select
                placeholder="Filter By Status"
                defaultValue="Open"
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={filterValue}
              >
                {statusOptions.map((status) => (
                    <Select.Option key={status} value={status}>
                      {status}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="case-ticket-clear">
            <Form.Item>
              <Button
                className="case-ticket-clear-button"
                type="secondary"
                onClick={clearFilterChange}
              >
                Clear
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <div className="case-ticket-list-data">
        <Spin
          size="large"
          spinning={caseTicketListLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="case-ticket-table"
              columns={columns}
              scroll={{ x: 900 }}
              size="large"
              pagination={{
                total: caseTicketCount,
                showTotal: () => `Total ${caseTicketCount} cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    handleCaseListClick(event, record);
                  },
                };
              }}
              dataSource={caseTicketList}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    caseTicketList,
    caseTicketListLoading,
    caseTicketCount,
  } = state.caseTickets;

  return {
    caseTicketList,
    caseTicketListLoading,
    caseTicketCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getCaseTicketList } = caseTickets.actions;
  return {
    actions: bindActionCreators(
      {
        getCaseTicketList,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseTicket);
